import React, { Fragment, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  cleanupCart,
  clearUploadCartImage,
  fetchCart,
  resetCartImage,
  saveCart,
  types,
} from 'generic/core/carts/actions';
import { toggleCheckbox, toggleCheckboxAll } from 'generic/core/selection/actions';
import GlobalLoading from 'generic/components/pages/GlobalLoading';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';
import { openDialogSendToFriend } from 'generic/core/actions/actions';
import { fetchResultsComplete } from 'generic/core/search/actions';
import ResultsCompleteContainer from 'generic/containers/ResultsCompleteContainer';

const Cart = lazy(() => import('generic/components/pages/Cart'));

const CartContainer = () => {
  const cart = useSelector((state) => state.carts.editCart);
  const cartState = useSelector((state) => state.carts.editCartState);
  const checkedItems = useSelector((state) => state.selection.checkedItems);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const uploadActions = {
    loading: { type: types.UPLOAD_CART_IMAGE },
    success: { type: types.UPLOAD_CART_IMAGE_SUCCESS },
    error: { type: types.UPLOAD_CART_IMAGE_ERROR },
  };

  useEffect(() => {
    if (!_.isEmpty(id)) {
      dispatch(fetchCart(id));
    }
  }, [id, dispatch]);

  // Cleanup du panier lorsque le composant est "unmount" (donc quand on change de page)
  useEffect(() => (
    () => dispatch(cleanupCart())
  ), [dispatch]);

  const handleToggleCheckbox = (panierDocument) => {
    dispatch(toggleCheckbox({
      id: panierDocument.idext,
      item: {
        title: '',
        documentitem: panierDocument.documentitem,
        panierdocument: panierDocument.panier_document,
      },
    }));
  };

  const handleToggleCheckboxAll = (event) => {
    const items = cart.panierDocuments.reduce((accumulateur, panierDocument) => {
      accumulateur[panierDocument.idext] = {
        title: '',
        documentitem: panierDocument.documentitem,
        panierdocument: panierDocument.panier_document,
      };
      return accumulateur;
    }, {});

    dispatch(toggleCheckboxAll({
      checked: event.target.checked,
      items,
    }));
  };

  const allDocs = _.map(cart.panierDocuments, 'idext');
  const allCheckedDocs = _.keys(checkedItems);
  const isCartEmpty = _.isEmpty(cart?.panierDocuments);
  const allArticlesChecked = !isCartEmpty && _.isEmpty(_.without(allDocs, ...allCheckedDocs));

  const handleOpenDeleteDocumentConfirmDialog = () => {
    const ids = _.map(checkedItems, 'panierdocument');
    dispatch(setConfirmDialogData({
      title: t('carts.delete'),
      message: `${t('carts.delete_cart_documents', { count: ids.length })}`,
      submitColor: 'error',
      waitForActions: [types.DELETE_CART_DOCUMENTS_SUCCESS, types.DELETE_CART_DOCUMENTS_ERROR],
      action: {
        type: types.DELETE_CART_DOCUMENTS,
        id,
        ids,
      },
    }));
    return false;
  };

  const handleOpenSendToFriend = () => {
    dispatch(openDialogSendToFriend());
    return false;
  };

  const handleSave = (values) => {
    dispatch(saveCart(values));
  };

  const handleClearUploadField = () => {
    dispatch(clearUploadCartImage());
  };

  const handleResetUploadField = () => {
    dispatch(resetCartImage());
  };

  const handleGetDocumentComplete = (iddoc, baseId) => {
    dispatch(fetchResultsComplete(iddoc, baseId));
  };

  if (cart.loading) {
    return <GlobalLoading />;
  }
  return (
    <Fragment>
      <Cart
        allArticlesChecked={allArticlesChecked}
        cart={cart}
        checkedItems={checkedItems}
        fileUploading={cartState.fileUploading}
        fileUploadName={cartState.fileUploadName}
        handleClearUploadField={handleClearUploadField}
        handleGetDocumentComplete={handleGetDocumentComplete}
        handleOpenDeleteDocumentConfirmDialog={handleOpenDeleteDocumentConfirmDialog}
        handleOpenSendToFriend={handleOpenSendToFriend}
        handleResetUploadField={handleResetUploadField}
        handleToggleCheckbox={handleToggleCheckbox}
        handleToggleCheckboxAll={handleToggleCheckboxAll}
        onSave={handleSave}
        saveCartLoading={cartState.saveCartLoading}
        uploadActions={uploadActions}
      />
      <ResultsCompleteContainer disableTagsRefine />
    </Fragment>
  );
};

export default CartContainer;
