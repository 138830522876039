import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import { Grid, SwipeableDrawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import {
  closeLeftDrawer,
  closeRightDrawer,
  openLeftDrawer,
  openRightDrawer,
} from 'generic/core/ux/actions';
import { CONSTANTS } from 'generic/core/constants';

const { DEFAULT_MAX_SIDE_CHILDREN_WIDTH } = CONSTANTS;

const useStyles = makeStyles((theme) => ({
  paper: {
    [theme.breakpoints.down('md')]: {
      width: '75%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '40%',
    },
    [theme.breakpoints.up('lg')]: {
      border: 'none',
      position: 'relative',
    },
  },
}));

const ContentGrid = ({
  leftChild,
  maxSideChildrenWidth,
  middleChild,
  rightChild,
}) => {
  const classes = useStyles();
  const leftDrawerOpened = useSelector((state) => state.ux.leftDrawerOpened);
  const rightDrawerOpened = useSelector((state) => state.ux.rightDrawerOpened);
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const {
    id,
    view,
  } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(leftChild)) {
      dispatch(closeLeftDrawer());
    }
    if (!_.isEmpty(rightChild)) {
      dispatch(closeRightDrawer());
    }
  }, [id, view, leftChild, rightChild, dispatch]);

  useEffect(() => {
    if (!smallerThanLarge) {
      if (!_.isEmpty(leftChild)) {
        dispatch(openLeftDrawer());
      }
      if (!_.isEmpty(rightChild)) {
        dispatch(openRightDrawer());
      }
    } else {
      if (!_.isEmpty(leftChild)) {
        dispatch(closeLeftDrawer());
      }
      if (!_.isEmpty(rightChild)) {
        dispatch(closeRightDrawer());
      }
    }
  }, [smallerThanLarge, leftChild, rightChild, dispatch]);

  const hasBothSideChildren = !_.isEmpty(leftChild) && !_.isEmpty(rightChild);
  const sizes = {
    // eslint-disable-next-line no-nested-ternary
    leftLg: hasBothSideChildren ? 2 : (!_.isEmpty(leftChild) ? maxSideChildrenWidth : 0),
    // eslint-disable-next-line no-nested-ternary
    rightLg: hasBothSideChildren ? 2 : (!_.isEmpty(rightChild) ? maxSideChildrenWidth : 0),
  };
  sizes.middleLg = 12 - sizes.leftLg - sizes.rightLg;
  return (
    <Grid
      container
      display="flex"
      flexGrow="1"
      flexWrap="nowrap"
      className="desktopOnlyOverflow"
    >
      {leftChild && (
        <Grid
          display="flex"
          overflow="auto"
          flexGrow="1"
          item
          lg={sizes.leftLg}
          zIndex="1"
        >
          <SwipeableDrawer
            className={clsx('overflowAuto', 'flexGrow1', 'width100')}
            onOpen={() => dispatch(openLeftDrawer())}
            onClose={() => dispatch(closeLeftDrawer())}
            anchor="left"
            open={leftDrawerOpened}
            variant={smallerThanLarge ? 'temporary' : 'permanent'}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              className: classes.paper,
              sx: { overflowY: smallerThanLarge ? 'auto' : 'unset' },
            }}
          >
            {leftChild}
          </SwipeableDrawer>
        </Grid>
      )}
      <Grid
        className={clsx(
          'positionRelative',
          'width100',
          'flexGrow1',
          'displayFlex',
          'desktopOnlyOverflow',
        )}
        item
        lg={sizes.middleLg}
        flexGrow="1"
        md={12}
      >
        {middleChild}
      </Grid>
      {rightChild && (
        <Grid
          display="flex"
          overflow="auto"
          flexGrow="1"
          item
          lg={sizes.rightLg}
        >
          <SwipeableDrawer
            className={clsx('overflowAuto', 'flexGrow1', 'width100')}
            onOpen={() => dispatch(openRightDrawer())}
            onClose={() => dispatch(closeRightDrawer())}
            anchor="right"
            open={rightDrawerOpened}
            variant={smallerThanLarge ? 'temporary' : 'permanent'}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              className: classes.paper,
              sx: { overflowY: smallerThanLarge ? 'auto' : 'unset' },
            }}
          >
            {rightChild}
          </SwipeableDrawer>
        </Grid>
      )}
    </Grid>
  );
};

ContentGrid.propTypes = {
  leftChild: PropTypes.node,
  middleChild: PropTypes.node.isRequired,
  rightChild: PropTypes.node,
  maxSideChildrenWidth: PropTypes.number,
};

ContentGrid.defaultProps = {
  leftChild: null,
  rightChild: null,
  maxSideChildrenWidth: DEFAULT_MAX_SIDE_CHILDREN_WIDTH,
};

export default React.memo(ContentGrid);
