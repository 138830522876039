import { createBrowserHistory } from 'history';
import {
  createStore,
  combineReducers,
  applyMiddleware,
} from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';

import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleWare from 'redux-saga';

import actions from 'generic/core/actions/reducer';
import alerts from 'generic/core/alerts/reducer';
import anr from 'generic/core/anr/reducer';
import auth from 'generic/core/auth/reducer';
import carts from 'generic/core/carts/reducer';
import comments from 'generic/core/comment/reducer';
import config from 'generic/core/config/reducer';
import confirmDialog from 'generic/core/confirmDialog/reducer';
import dashboard from 'generic/core/dashboard/reducer';
import exportReducer from 'generic/core/export/reducer';
import files from 'generic/core/files/reducer';
import ged from 'generic/core/ged/reducer';
import mailingLists from 'generic/core/mailingLists/reducer';
import newsletters from 'generic/core/newsletters/reducer';
import rootSagas from 'generic/core/rootSagas';
import runSaga from 'generic/core/configureSaga';
import search from 'generic/core/search/reducer';
import selection from 'generic/core/selection/reducer';
import sendToFriend from 'generic/core/sendToFriend/reducer';
import users from 'generic/core/users/reducer';
import ux from 'generic/core/ux/reducer';
import { CUSTOM_PATH } from 'generic/core/constants';

const sagaMiddleware = createSagaMiddleWare();

export const history = createBrowserHistory();

const reducers = {
  actions,
  alerts,
  anr,
  auth,
  carts,
  comments,
  config,
  confirmDialog,
  dashboard,
  exportReducer,
  files,
  ged,
  mailingLists,
  newsletters,
  search,
  selection,
  sendToFriend,
  users,
  ux,
};

if (CUSTOM_PATH) {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    const { default: customReducer } = require(`custom/${CUSTOM_PATH}/core/redux/reducer`);
    if (customReducer) {
      reducers[CUSTOM_PATH] = customReducer;
    }
  } catch (err) {
    console.info('Pas de fichier Reducer custom');
  }
}

const rootReducer = (_history) => combineReducers({
  router: connectRouter(_history),
  ...reducers,
});

export default function configureStore() {
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const store = createStore(
    rootReducer(history),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
      ),
    ),
  );

  runSaga(sagaMiddleware, rootSagas);

  return store;
}
