import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Fab,
  Stack,
  Tooltip,
  Zoom,
} from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import {
  AddShoppingCart,
  DeleteForever,
  Edit,
  ForwardToInbox,
  MailOutlined,
  Wysiwyg,
} from '@mui/icons-material';
import { setSingleItem } from 'generic/core/selection/actions';
import { openDialogAddToCart, openDialogAddToNewsletter, openDialogSendToFriend } from 'generic/core/actions/actions';
import { setMultiple, types } from 'generic/core/ged/actions';
import { setBackPageLocation } from 'generic/core/ux/actions';
import { cleanupResultsComplete } from 'generic/core/search/actions';
import { setConfirmDialogData } from 'generic/core/confirmDialog/actions';

const ResultSingleActions = ({
  canAddToCart,
  canAddToNewsletter,
  canEditInGED,
  canFastEditInGed,
  canDeleteDocument,
  canSendToFriend,
  document,
  handleOpenFastGed,
  visible,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const zoomProps = {
    in: visible,
    timeout: 150,
    unmountOnExit: true,
  };
  const fabProps = {
    size: 'extraSmall',
    color: 'secondary',
  };

  const handleOpenAddToCartSingleItem = () => {
    dispatch(setSingleItem(document));
    dispatch(openDialogAddToCart());
  };

  const handleOpenAddToNewsletterSingleItem = () => {
    dispatch(setSingleItem(document));
    dispatch(openDialogAddToNewsletter());
  };

  const handleOpenSendToFriendSingleItem = () => {
    dispatch(setSingleItem(document));
    dispatch(openDialogSendToFriend());
  };

  const handleOpenGed = () => {
    dispatch(setMultiple(false));
    dispatch(setBackPageLocation(location));
    dispatch(cleanupResultsComplete());
    history.push(`/ged/${document.base}/${encodeURIComponent(document.idext)}`);
  };

  const handleOpenDeleteSingleArticleDialog = () => {
    dispatch(setSingleItem(document));
    dispatch(setConfirmDialogData({
      title: t('actions.delete'),
      message: (
        <Fragment>
          {t('ged.delete_documents', { count: 1 })}
        </Fragment>
      ),
      submitColor: 'error',
      waitForActions: [types.DELETE_DOCUMENTS_SUCCESS, types.DELETE_DOCUMENTS_ERROR],
      action: {
        type: types.DELETE_DOCUMENTS,
        refresh: true,
      },
    }));
  };

  if (!visible) return null;

  const actions = [];

  const createButton = (coeff, title, onClick, color, children) => {
    const msDelay = 35;
    const transitionDelay = `${coeff * msDelay}ms`;

    return (
      <Tooltip
        key={`single_action_${coeff}`}
        title={title}
      >
        <Zoom
          {...zoomProps}
          style={{
            transitionDelay,
          }}
        >
          <Fab
            {...fabProps}
            color={color}
            onClick={onClick}
          >
            {children}
          </Fab>
        </Zoom>
      </Tooltip>
    );
  };

  if (canAddToCart) {
    const coeff = actions.length + 1;
    const title = t('actions.add_to_cart');
    const onClick = handleOpenAddToCartSingleItem;
    const children = <AddShoppingCart />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canAddToNewsletter) {
    const coeff = actions.length + 1;
    const title = t('actions.add_to_newsletter');
    const onClick = handleOpenAddToNewsletterSingleItem;
    const children = <ForwardToInbox />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canSendToFriend) {
    const coeff = actions.length + 1;
    const title = t('actions.send_to_friend');
    const onClick = handleOpenSendToFriendSingleItem;
    const children = <MailOutlined />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canEditInGED) {
    const coeff = actions.length + 1;
    const title = t('form.edit');
    const onClick = handleOpenGed;
    const children = <Edit />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canFastEditInGed) {
    const coeff = actions.length + 1;
    const title = t('form.edit_fast');
    const onClick = handleOpenFastGed;
    const children = <Wysiwyg />;
    actions.push(createButton(coeff, title, onClick, 'primary', children));
  }

  if (canDeleteDocument) {
    const coeff = actions.length + 1;
    const title = t('actions.delete');
    const onClick = handleOpenDeleteSingleArticleDialog;
    const children = <DeleteForever />;
    actions.push(createButton(coeff, title, onClick, 'warning', children));
  }

  return (
    <Stack
      spacing={0.5}
      direction="row"
      component="span"
    >
      {actions}
    </Stack>
  );
};

ResultSingleActions.propTypes = {
  canAddToCart: PropTypes.bool.isRequired,
  canAddToNewsletter: PropTypes.bool.isRequired,
  canEditInGED: PropTypes.bool.isRequired,
  canFastEditInGed: PropTypes.bool.isRequired,
  canDeleteDocument: PropTypes.bool.isRequired,
  canSendToFriend: PropTypes.bool.isRequired,
  document: PropTypes.shape(),
  handleOpenFastGed: PropTypes.func,
  visible: PropTypes.bool,
};
ResultSingleActions.defaultProps = {
  document: {},
  handleOpenFastGed: null,
  visible: false,
};

const Memoized = React.memo(ResultSingleActions);
export default Memoized;
