import { useSelector } from 'react-redux';

import _ from 'lodash';
import { format } from 'generic/utils/dateUtils';
import { t } from 'i18next';
import {
  createHtmlAnnotationTag,
  generateLinkToResource, getDocumentBaseId, getValueByBaseFieldsConfig, getValueOrFirstValueFromArray,
} from 'generic/utils/qesUtils';

export default (document) => {
  const { qesdocument } = document;

  const docBaseId = getDocumentBaseId(document);
  const bases = useSelector((state) => state.config.bases);
  const resultsCompleteVisibleInResults = useSelector((state) => state.ux.resultsCompleteVisibleInResults);
  const documentBase = _.find(bases, { base: docBaseId });

  // noms des champs issues de la configuration de la base, permet d'être un peu dynamique
  // et avec de la chance, ne rien avoir à surcharger
  const id = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champId');
  const statusXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champEtat');
  const titleXml = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champTitre');
  const pdf = getValueByBaseFieldsConfig(documentBase, qesdocument, 'champPdf');

  // noms des champs en durs, difficile de les rendre dynamique en l'état de l'API
  // on prend le premier élément du tableau de valeur s'il y en a plusieur.
  const text = getValueOrFirstValueFromArray(qesdocument?.TXT_CLEAN || qesdocument?.TEXTE);
  const datePubXml = getValueOrFirstValueFromArray(qesdocument?.PUB_DATE);
  const thumbXml = getValueOrFirstValueFromArray(qesdocument?.IMAGE || qesdocument?.THUMB);
  const linkout = getValueOrFirstValueFromArray(qesdocument?.URL || qesdocument?.QUOTE_URL);
  const source = getValueOrFirstValueFromArray(qesdocument?.SOURCE);

  let htmlWithAnnotations = getValueOrFirstValueFromArray(qesdocument?.QES_Html);
  if (htmlWithAnnotations) {
    // On traite le texte contenant les annotations sous formes de balises, exemple :
    // <Coldconcept>Bla bla bla</Coldconcept> <Concept>bli bla blu</Concept>
    // <Sentiment>aime <Coldconcept>le chocolat</Coldconcept></SentimentUncategorized>
    htmlWithAnnotations = htmlWithAnnotations.replaceAll('\n', '\n\n');
    const tagsToRemove = [
      'Relation',
      'Sentiment',
      'SentimentUncategorized',
      'Unknown',
      'Unlocalized',
      'Ambiguous',
    ];
    // On vire tous les couple de tags qu'on ne veut pas garder
    tagsToRemove.forEach((tagToRemove) => {
      htmlWithAnnotations = htmlWithAnnotations.replaceAll(new RegExp(`</?${tagToRemove}>`, 'g'), '');
    });
    // On remplace chaque "balise" ouvrante par une balise span du style :
    // <span class="html-tag Coldconcept" type-text="concept froid">
    // (a) savoir que le style et l'affichage des annotations est géré dans le CSS du thème)
    htmlWithAnnotations = htmlWithAnnotations.replaceAll(
      /<([a-zA-Z]+)>/g,
      (tag, captured) => createHtmlAnnotationTag(captured),
    );
    // On remplace chaque "balise" fermante par une balise fermante </span>
    htmlWithAnnotations = htmlWithAnnotations.replaceAll(
      /<\/[a-zA-Z]+>/g,
      '</span>',
    );
  }

  // dernières retouches avant le retour du résulat
  let thumb;
  if (thumbXml) {
    thumb = qesdocument?.IMAGE ? thumbXml : generateLinkToResource(docBaseId, thumbXml);
  }
  const title = titleXml || `[${t('document.title_undefined')}]`;
  const status = +statusXml;
  const date = format(datePubXml);
  const snippet = _.truncate(text, {
    length: resultsCompleteVisibleInResults ? 150 : 500,
    separator: /,? +/,
  });

  return {
    date,
    id,
    pdf,
    thumb,
    snippet,
    status,
    text,
    title,
    linkout,
    source,
    htmlWithAnnotations,
  };
};
