import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  useMediaQuery,
} from '@mui/material';
import {
  ViewSidebar,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import TooltipButton from 'generic/components/ui/TooltipButton';
import ResultsContainer from 'generic/containers/ResultsContainer';
import { openLeftDrawer } from 'generic/core/ux/actions';
import ContentGrid from 'generic/components/pages/ContentGrid';
import FacetsContainer from 'generic/containers/FacetsContainer';
import DateHistogramContainer from 'generic/containers/DateHistogramContainer';
import QES_CONSTANTS from 'generic/core/qes/constants';
import ResultsHeaderContainer from 'generic/containers/ResultsHeaderContainer';

const { MAX_FACET_COLUMN_WIDTH } = QES_CONSTANTS;

const ResultsWrapper = () => {
  const activeBaseFacetPosition = useSelector((state) => state.config.activeBase.facetPosition);
  const activeBaseNbFacetsRight = useSelector((state) => state.config.activeBase.facetNombreDroite);
  const activeBaseNbFacetsLeft = useSelector((state) => state.config.activeBase.facetNombreGauche);
  const answerOfRAG = useSelector((state) => state.search.results.qaReponse);
  const formStrategy = useSelector((state) => state.search.results.strategie);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallerThanLarge = useMediaQuery(theme.breakpoints.down('lg'));

  const nbFacetsLeftFinal = smallerThanLarge ? (
    activeBaseNbFacetsLeft + activeBaseNbFacetsRight
  ) : (
    activeBaseNbFacetsLeft
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow="1"
      width="100%"
      className="desktopOnlyOverflow"
    >
      {!answerOfRAG && (
        <Box
          display="flex"
          alignItems="flex-start"
          width="inherit"
        >
          {smallerThanLarge && formStrategy && (
            <TooltipButton
              title={t('results.facets.show_facets')}
              tag="iconButton"
              onClick={() => dispatch(openLeftDrawer())}
            >
              <ViewSidebar />
            </TooltipButton>
          )}
          <ResultsHeaderContainer />
        </Box>
      )}
      <ContentGrid
        maxSideChildrenWidth={MAX_FACET_COLUMN_WIDTH}
        leftChild={['left', 'left_right'].includes(activeBaseFacetPosition) && (
          // On ne renvoie le FacetsContainer que si le paramétrage de la base l'indique
          <FacetsContainer side={smallerThanLarge ? null : 'left'} nbFacets={nbFacetsLeftFinal} />
        )}
        middleChild={(<ResultsContainer />)}
        rightChild={['left_right', 'right'].includes(activeBaseFacetPosition) && (
          // On ne renvoie le FacetsContainer que si le paramétrage de la base l'indique
          // ET qu'on est pas en mobile (sinon les facettes sont toutes dans une sideNav
          // à gauche)
          smallerThanLarge ? null : <FacetsContainer side="right" nbFacets={activeBaseNbFacetsRight} />
        )}
      />
      {!smallerThanLarge && !answerOfRAG && formStrategy && (
        <DateHistogramContainer />
      )}
    </Box>
  );
};

export default ResultsWrapper;
